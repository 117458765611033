// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { PrivateRoute } from "./utility/PrivateRoute";
import "./styles/index.scss";
// import Authentication from "./features/authentication/Authentication";
// import NPCList from "./features/npc-list/NPCList";
// import NPCView from "./features/npc-view/NPCView";
// import Account from "./features/account/Account";

function App(): JSX.Element {

  window.location.replace("https://voxstoria.beneverending.app/");

  return (
    <></>
    // <Router>
    //   <Routes>
    //     <Route path="/login" element={<Authentication />} />
    //     <Route path="*" element={<div>Page not found.</div>} />
    //     <Route path="/" element={<PrivateRoute />}>
    //       <>
    //         <Route path="/" element={<NPCList />} />
    //         <Route path="/npc-view/:id" element={<NPCView />} />
    //         <Route path="/account" element={<Account />} />
    //       </>
    //     </Route>
    //   </Routes>
    // </Router>
  );
}

export default App;
